<template>
    <vx-card title="Emergency Canvas Stock Movement">
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <h4>Regular Canvas</h4>
                <hr>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Canvas Document</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            v-model="code"
                            class="w-full"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Document Type</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <v-select
                            :filterable="true"
                            :options="typeOptions"
                            v-model="selectedType"
                            label="name"
                            :clearable="false"
                        />
                    </div>
                </div>
                <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>Movement Operation</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            v-model="movementOperation"
                            class="w-full"
                        />
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="w-full pr-6 md:basis-1/2">
                    <h4>Movement POD ZDCV</h4>
                    <hr>
                    <div class="mb-6 vx-row pt-2">
                    <div class="flex items-center w-full vx-col sm:w-1/3">
                        <span>POD Code</span>
                    </div>
                    <div class="w-full vx-col sm:w-2/3">
                        <vs-input
                            type="text"
                            v-model="podCode"
                            class="w-full"
                        />
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="vx-col sm:w-1/1 w-full" style="margin-top: 50px;">
            <div style="
                position: absolute;
                left: 50%;
                -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            ">
                <vs-button class="mr-3 mb-2" @click="handleUnreserve()">Unreserve</vs-button>
                <vs-button class="mr-3 mb-2" @click="handleMovement()">Movement</vs-button>
                <vs-button class="mr-3 mb-2" @click="handleMovementSoCanvas()">Movement SO Canvas</vs-button>
            </div>
        </div>
        <br>
    </vx-card>
</template>

<script>
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    data() {
        return {
            code: "",
            podCode: "",
            movementOperation: "",
            typeOptions: [
                {
                    value: true,
                    name: "Outbound",
                },
                {
                    value: false,
                    name: "Inbound",
                }
            ],
            selectedType: {
                value: false,
                name: "",
            }
        }
    },
    mounted() {
        this.selectedType = this.typeOptions[0]
    },
    methods: {
        handleUnreserve() {
            if (this.code == "") {
                this.$vs.dialog({
                    color: "danger",
                    title: "Blank document code",
                    text: "Please fill document code",
                });
                return false;
            }

            let params = {
                code: this.code,
                operation: "unreserve",
                is_outbound: this.selectedType.value,
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to unreserve this document?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/loading/emergency-movement", params)
                    .then(resp => {
                        if (resp.code == 200) {
                            console.log("unreserved")
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "success",
                                title: "Unreserve data",
                                text: resp.message,
                            })
                        } else {
                            console.log("error unreserved => ", resp.message)
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
                }
            });
        },
        handleMovement() {
            if (this.code == "") {
                this.$vs.dialog({
                    color: "danger",
                    title: "Blank document code",
                    text: "Please fill document code",
                });
                return false;
            }

            if (this.movementOperation == "") {
                this.$vs.dialog({
                    color: "danger",
                    title: "Blank movement operation",
                    text: "Please fill movement operation",
                });
                return false;
            }

            let params = {
                code: this.code,
                movement_operation: this.movementOperation,
                operation: "stock-movement",
                is_outbound: this.selectedType.value,
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to force movement stock this document?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/loading/emergency-movement", params)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "success",
                                title: "Stock movement",
                                text: resp.message,
                            })
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
                }
            });
        },
        handleMovementSoCanvas() {
            if (this.podCode == "") {
                this.$vs.dialog({
                    color: "danger",
                    title: "Blank document POD Code",
                    text: "Please fill document POD code",
                });
                return false;
            }

            let params = {
                pod_code: this.podCode,
                operation: "pod-movement",
            }

            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "Are you sure to force movement stock this POD document?",
                accept: () => {
                    this.$vs.loading();
                    this.$http.post("/api/wms/v1/canvas/loading/emergency-movement", params)
                    .then(resp => {
                        if (resp.code == 200) {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "success",
                                title: "Stock movement",
                                text: resp.message,
                            })
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                color: "danger",
                                title: "Something went wrong",
                                text: resp.message,
                            })
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
                }
            });
        }
    }
}
</script>